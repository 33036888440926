import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PrimeVue from 'primevue/config'

import BooleanDisplay from './components/BooleanDisplay.vue'
import LoaderSimple from './components/LoaderSimple.vue'
import { fontawesomeSetup } from './fontawesomeSetup'
import { primevueSetup } from './primevueSetup'

import App from './App.vue'
import router from './router'

// import 'primevue/resources/primevue.min.css'
// import 'primevue/resources/themes/lara-light-blue/theme.css'
import './assets/styles/main.scss'
import './assets/styles/aura-light-noir/theme.css'
import 'primeflex/primeflex.min.css'

const app = createApp(App)
const head = createHead()

app.use(head)

Sentry.init({
  app,
  dsn: 'https://7d9f1a7dc5634334900fffadd478f12d@o214277.ingest.sentry.io/1352389',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: import.meta.env.VITE_ENV === 'development' ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_ENV
})

// For Sentry identification of site major version
Sentry.setTag('site_version', 'v3')

app.use(router)
app.use(createPinia())
app.use(PrimeVue)
fontawesomeSetup()
app.component('FaIcon', FontAwesomeIcon)
app.component('BooleanDisplay', BooleanDisplay)
app.component('LoaderSimple', LoaderSimple)
await primevueSetup(app)

app.mount('#app')
