<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useMenuStore } from '../stores/MenuStore'

interface Props {
  item: any
  index: number
  root?: boolean
  parentItemKey?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  item: () => ({}),
  index: 0,
  root: true,
  parentItemKey: null
})

const menuStore = useMenuStore()
const route = useRoute()
const isActiveMenu = ref(false)
const itemKey = ref(null as null | string)

onBeforeMount(() => {
  itemKey.value = props.parentItemKey ? `${props.parentItemKey}-${props.index}` : `${props.index}`

  const activeItem = menuStore.activeMenuItem

  isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(`${itemKey.value}-`) : false
})

watch(
  () => menuStore.activeMenuItem,
  (newVal) => {
    // Set _key as newVal may or may not have `.value` depending on nested level
    const _key = newVal?.value || newVal
    isActiveMenu.value = _key === itemKey.value || _key.startsWith(`${itemKey.value}-`)
  }
)

// If routing to a new page then close menu
watch(
  () => route.path,
  (newVal, oldVal) => {
    if (newVal !== oldVal && menuStore.showMenu)
      menuStore.setShowMenu()
  }
)

function itemClick(event: any, item: any) {
  if (item.disabled) {
    event.preventDefault()
    return
  }

  if (item.command)
    item.command({ originalEvent: event, item })

  const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value
  menuStore.setActiveMenuItem(foundItemKey)
}

function checkActiveRoute(item: any) {
  return route.path === item.to
}
</script>

<template>
  <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
    <div
      v-if="root"
      class="layout-menuitem-root-text"
    >
      {{ item.label }}
    </div>
    <a
      v-if="!item.to || item.items"
      :href="item.url"
      :class="item.class"
      :target="item.target"
      tabindex="0"
      @click="itemClick($event, item)"
    >
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <fa-icon
        v-if="item.items"
        icon="fa fa-fw fa-angle-down"
        class="layout-submenu-toggler"
      />
    </a>
    <router-link
      v-if="item.to && !item.items"
      class="router-link"
      :class="[item.class, { 'active-route': checkActiveRoute(item) }]"
      tabindex="0"
      :to="item.to"
      @click="itemClick($event, item)"
    >
      <fa-icon
        v-if="item.icon"
        :icon="item.icon"
        class="layout-menuitem-icon"
      />
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <fa-icon
        v-if="item.items"
        icon="fa fa-fw fa-angle-down"
        class="layout-submenu-toggler"
      />
    </router-link>
    <Transition
      v-if="item.items"
      name="layout-submenu"
    >
      <ul
        v-show="root ? true : isActiveMenu"
        class="layout-submenu"
      >
        <app-menu-item
          v-for="(child, i) in item.items"
          :key="child"
          :index="i"
          :item="child"
          :parent-item-key="itemKey"
          :root="false"
        />
      </ul>
    </Transition>
  </li>
</template>
