import { ref } from 'vue'
import { breakpointsPrimeFlex, useBreakpoints as useVueUseBreakpoints } from '@vueuse/core'

export function useBreakpoints() {
  const breakpoints: any = useVueUseBreakpoints({
    ...breakpointsPrimeFlex,
    hideListSidebar: 1100
  })
  const smWidth = breakpointsPrimeFlex.sm
  const current = breakpoints.current()
  const xs = breakpoints.smaller('sm')
  const xse = breakpoints.smallerOrEqual('sm')
  const smallAndBelow = ref(breakpoints.smaller('md'))
  const sm = breakpoints.between('sm', 'md')
  const md = ref(breakpoints.between('md', 'lg'))
  const mdAndBelow = ref(breakpoints.smaller('lg'))
  const lg = breakpoints.between('lg', 'xl')
  const hideListSidebar = breakpoints.smallerOrEqual('hideListSidebar')
  const xl = breakpoints.greaterOrEqual('xl')

  return {
    current,
    hideListSidebar,
    lg,
    md,
    mdAndBelow,
    sm,
    smallAndBelow,
    smWidth,
    xl,
    xs,
    xse
  }
}
