<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useFathom } from './composables/fathom'

// Initialize Fathom using @unhead/useScript in Fathom composable. Better way?
// eslint-disable-next-line unused-imports/no-unused-vars
const { fathom } = useFathom()

useHead({
  titleTemplate: (title?: string) => title?.length ? `${title} | Daughtridge Sales` : 'Pressure, Diaphragm Seals, Bimetals... | Daughtridge Sales'
})
</script>

<template>
  <router-view />
</template>
