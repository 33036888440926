import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMenuStore = defineStore('MenuStore', () => {
  const headerNavLinks = ref([
    {
      label: 'Products',
      to: '/products/base-models'
    },
    {
      label: 'Customer Service',
      to: '/customer-service'
    }
  ])

  const navLinks = ref([
    {
      label: '',
      items: [
        {
          label: 'Home',
          icon: 'fa-regular fa-house',
          to: '/'
        },
        {
          label: 'Customer Service',
          icon: 'fa fa-fw fa-user-headset',
          to: '/customer-service'
        }
      ]
    },
    {
      label: '',
      items: [
        {
          label: 'Products',
          icon: 'fa-regular fa-fw fa-gauge-high',
          to: '/products/base-models'
        }
      ]
    },
    {
      label: '',
      items: [
        {
          label: 'Services',
          items: [
            {
              label: 'Calibration',
              icon: 'fa-regular fa-fw fa-gauge-high',
              to: '/services/calibration'
            },
            {
              label: 'Government/GSA',
              icon: 'fa-regular fa-fw fa-building-columns',
              to: '/government'
            },
            {
              label: 'Laser Marking/Engraving',
              icon: 'fa-regular fa-fw fa-gauge-high',
              to: '/services/laser-marking-engraving'
            },
            {
              label: 'Lead Times & Shipping',
              icon: 'fa-regular fa-fw fa-truck-fast',
              to: '/lead-times-and-shipping'
            }
          ]
        }
      ]
    },
    {
      label: '',
      items: [
        {
          label: 'Resources',
          items: [
            {
              label: 'Pressure Accuracy',
              to: '/resources/pressure-accuracy'
            },
            {
              label: 'Pressure Gauge Venting',
              to: '/pressure-gauge-venting'
            },
            {
              label: 'Why Should You Use A Diaphragm Seal',
              to: '/why-should-you-use-a-diaphragm-seal'
            }
          ]
        }
      ]
    }
  ])
  const showMenu = ref(false) // overlayMenuActive

  function setShowMenu() {
    showMenu.value = !showMenu.value
  }

  const activeMenuItem = ref(null) as null | any
  function setActiveMenuItem(item: any) {
    activeMenuItem.value = item
  }

  return {
    activeMenuItem,
    headerNavLinks,
    navLinks,
    setShowMenu,
    setActiveMenuItem,
    showMenu
  }
})
