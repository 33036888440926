<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside, onKeyUp } from '@vueuse/core'
import { useMenuStore } from '../stores/MenuStore'
import AppMenuItem from '../components/AppMenuItem.vue'

const menuStore = useMenuStore()

// Close sidebar menu
const target = ref(null)
onClickOutside(target, () => menuStore.showMenu = false)
onKeyUp('Escape', (e) => {
  e.preventDefault()
  menuStore.showMenu = false
})
</script>

<template>
  <div
    ref="target"
    class="layout-sidebar"
    :class="menuStore.showMenu ? ' shadow-8' : ''"
  >
    <img
      src="/images/Daughtridge-Sales-Color-NoSpacing.svg"
      alt="Logo for Daughtridge Sales an MCE company"
      style="width: 100%"
    >
    <ul class="layout-menu">
      <template
        v-for="(item, idx) in menuStore.navLinks"
        :key="item.label"
      >
        <app-menu-item
          :item="item"
          :index="idx"
        />
        <!-- <sidebar-item :item="i" /> -->
        <!-- <li
          v-if="i.separator"
          class="menu-separator"
        /> -->
      </template>
    </ul>
  </div>
</template>
