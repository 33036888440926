import { useScript } from '@unhead/vue'
import type { ObjectKeys } from '../interfaces/ObjectKeys'

export function useFathom() {
  const fathom = useScript<any>({
    'src': 'https://cdn.usefathom.com/script.js',
    'data-spa': 'auto',
    'data-site': 'IHBYAKBP',
    'defer': false,

  }, {
    use: () => (window as any)?.fathom
  })

  const FathomEventNames: ObjectKeys = {
    DownloadDatasheet: 'download-datasheet',
    ViewRelatedModel: 'view-related-model',
    GoToMceSite: 'go-to-mce-site',
    PhoneCall: 'phone-call'
  }

  const FathomEvents = {
    DownloadDatasheet: (payload: any | null = null) => buildEventString(FathomEventNames.DownloadDatasheet, payload),
    ViewRelatedModel: (payload: any | null = null) => buildEventString(FathomEventNames.ViewRelatedModel, payload),
    GoToMceSite: (payload: any | null = null) => buildEventString(FathomEventNames.GoToMceSite, payload),
    PhoneCall: (payload: any | null = null) => buildEventString(FathomEventNames.PhoneCall, payload)
  }

  function buildEventString(eventName: string, payload: string | string[] | null) {
    let str = `${eventName}`
    if (!payload || !payload.length)
      return str.toLowerCase()

    if (Array.isArray(payload) && payload.length)
      str = `${str}::${payload.join(' ')}`
    else
      str = `${str}::${payload.toString()}`
    return str.toLowerCase()
  }

  return {
    fathom,
    FathomEventNames,
    FathomEvents
  }
}
