<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useMenuStore } from '../stores/MenuStore'
import { useBreakpoints } from '../composables/breakpoints'
import AppSidebar from './AppSidebar.vue'

const {
  smallAndBelow
} = useBreakpoints()

const route = useRoute()
const menuStore = useMenuStore()
</script>

<template>
  <header class="layout-topbar">
    <!-- <header class="surface-overlay px-3 sm:px-7 flex flex-wrap align-items-stretch justify-content-between relative"> -->
    <div class="flex align-items-center justify-content-between lg:justify-content-center">
      <a
        v-ripple
        class="cursor-pointer flex align-items-center text-700 mr-3 p-ripple"
        @click="menuStore.setShowMenu"
      >
        <fa-icon icon="fa-bars" class="text-3xl text-gray-500" />
      </a>
      <router-link :to="route.name !== 'home' ? '/' : ''">
        <img
          src="/images/Daughtridge-Sales-Color-NoSpacing.svg"
          alt="Daughtridge Sales and MCE company"
          :height="smallAndBelow ? 38 : 60"
        >
      </router-link>
    </div>

    <!-- Desktop Nav -->
    <nav
      class="surface-overlay hidden lg:flex absolute lg:static left-0 top-100 z-1 shadow-2 lg:shadow-none w-full lg:w-auto py-3 lg:py-0 h-full"
    >
      <ul class="list-none p-0 m-0 flex flex-column lg:flex-row">
        <li
          v-for="i in menuStore.headerNavLinks"
          :key="i.label"
          class="flex flex-column lg:flex-row"
        >
          <router-link
            v-if="i.to"
            v-ripple
            :to="i.to"
            class="router-link flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-red-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <span>
              {{ i.label }}
            </span>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
  <!-- Mobile Sidebar -->
  <app-sidebar />
</template>
