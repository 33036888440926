import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBuildingColumns,
  faGaugeHigh,
  faHouse,
  faTruckFast
} from '@fortawesome/pro-regular-svg-icons'

import {
  faAngleDown,
  faAsterisk,
  faBars,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronCircleRight,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faEnvelope,
  faExternalLink,
  faFilePdf,
  faInfo,
  faInfoCircle,
  faPaperPlane,
  faPhone,
  faSearch,
  faShippingFast,
  faShoppingCart,
  faStar,
  faSync,
  faTimes,
  faUserHeadset
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCircleNotch as faCircleNotchLight,
  faFileInvoice as faFileInvoiceLight,
  faLightbulbOn,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faAngleDown,
  faAsterisk,
  faBars,
  faBuildingColumns,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronCircleRight,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCircleNotchLight,
  faEnvelope,
  faExternalLink,
  faFileInvoiceLight,
  faFilePdf,
  faGaugeHigh,
  faHouse,
  faInfo,
  faInfoCircle,
  faLightbulbOn,
  faPaperPlane,
  faPhone,
  faSearch,
  faShippingFast,
  faShoppingCart,
  faStar,
  faSync,
  faTimes,
  faTruckFast,
  faUserHeadset
)

export function fontawesomeSetup() {
  return library
}
