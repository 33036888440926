export default [
  {
    path: '/products',
    children: [
      {
        path: 'base-models',
        name: 'base-models-list',
        component: () => import('../views/BaseModelsList.vue')
      }
    ]
  },
  {
    path: '/models',
    redirect: '/products/base-models'
  },
  {
    path: '/models/:modelUri',
    name: 'base-model-single',
    component: () => import('../views/BaseModelWrapper.vue')
  }
]
