<script setup lang="ts">

</script>

<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div
      class="bg-gray-500 text-0 p-4"
      style="border-radius: 10px"
    >
      <div class="flex flex-column md:flex-row md:justify-content-between xl:justify-content-evenly">
        <span class="inline-flex align-items-center md:align-items-start lg:align-items-center mb-3 md:mb-0">
          <fa-icon
            icon="fas fa-shopping-cart"
            class="text-primary xl:text-2xl mr-3"
          />
          <span class="text-base xl:text-2xl font-medium">In Stock- Same Day Shipping
            <router-link
              to="/lead-times-and-shipping"
              class="router-link"
            >
              <sup class="text-white font-bold">*</sup>
              <sup class="text-primary font-bold">&Dagger;</sup>
            </router-link>
          </span>
        </span>
        <span class="inline-flex align-items-center md:align-items-start lg:align-items-center mb-3 md:mb-0">
          <fa-icon
            icon="fas fa-check"
            class="text-primary xl:text-2xl mr-3"
          />
          <span class="text-base xl:text-2xl font-medium">Certified Seal Assembler</span>
        </span>
        <span class="inline-flex align-items-center md:align-items-start lg:align-items-center mb-3 md:mb-0">
          <fa-icon
            icon="fas fa-star"
            class="text-primary xl:text-2xl mr-3"
          />
          <span class="text-base xl:text-2xl font-medium">GSA Government Procurement</span>
        </span>
      </div>
    </div>
  </div>
</template>
