export default [
  {
    path: '/resources',
    children: [
      {
        path: 'pressure-accuracy',
        name: 'resources-pressure-accuracy',
        component: () => import(/* webpackChunkName: "pressure-accuracy" */ '../views/PressureAccuracy.vue')
      },
    ]
  },
  // Not in '/resources' path
  {
    path: '/burping-your-gauges',
    alias: 'pressure-gauge-venting',
    name: 'burping-venting-gauges',
    component: () => import('../views/BurpingVentingGauges.vue')

  },
  {
    path: '/why-should-you-use-a-diaphragm-seal',
    name: 'use-a-diaphragm-seal',
    component: () => import('../views/UseADiaphragmSeal.vue')
  },
]
