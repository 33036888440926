<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import PromoBar from '../components/PromoBarSimple.vue'
import LogoCloud from '../components/LogoCloud.vue'
import { useAppStore } from '../stores/AppStore'
import { useFathom } from '../composables/fathom'

const appStore = useAppStore()

const year = new Date().getFullYear()

const DevBreakpoints = defineAsyncComponent(() => import('../components/DevBreakpoints.vue'))

const { fathom, FathomEventNames, FathomEvents } = useFathom()
function trackEvent(e: any, payload: any = {}) {
  const events: any = {
    [FathomEventNames.GoToMceSite]: () => fathom.trackEvent(FathomEvents.GoToMceSite()),
    [FathomEventNames.PhoneCall]: () => fathom.trackEvent(FathomEvents.PhoneCall(payload))
  }
  events[e]()
}
</script>

<template>
  <promo-bar />
  <section class="surface-section px-4 md:px-6 lg:px-8">
    <span class="font-italic font-bold">
      Direct access to these vendors and more...
    </span>
    <logo-cloud />
  </section>

  <footer class="bg-gray-500 bg-favicon-faded text-white px-4 md:px-6 lg:px-8">
    <div class="grid grid-nogutter text-center lg:text-left">
      <div class="col-12 sm:col-6 lg:col-3 py-4 flex flex-order-0 flex-column align-items-center lg:align-items-start">
        <img
          src="/images/Daughtridge-Sales-White-NoSpacing.svg"
          class="w-20rem lg:w-14rem mx-auto lg:mx-0"
          alt="Daughtridge Sales Logo"
        >
        <span class="block my-4">
          <a
            class="border-round hover:bg-white hover:text-red-500 px-2 py-1 text-white text-xl md:text-2xl font-bold text-decoration-none"
            href="tel:+12529771131"
            @click="trackEvent(FathomEventNames.PhoneCall, '12529771131-footer')"
          >
            <fa-icon
              icon="fa fa-phone"
              class="hidden sm:inline text-primary-500 text-xl mr-2"
            />
            (252) 977-1131
          </a>
        </span>
        <div>
          <span class="block">600 S Grace St</span>
          <span class="block">PO Box 4364</span>
          <span class="block">Rocky Mount,
            <abbr title="North Carolina">NC</abbr> 27803</span>
          <span class="block mt-2">
            Toll Free
            <a
              class="text-white text-decoration-none"
              href="tel:+18009677507"
              @click="trackEvent(FathomEventNames.PhoneCall, '18009677507-footer')"
            >
              (800) 967-7507
            </a>
          </span>
          <span class="d-block">
            Fax <span class="text-white text-decoration-none">(252) 977-2192</span>
          </span>
        </div>
      </div>
      <div class="col-12 sm:col-6 lg:col-3 py-4 flex flex-order-2 lg:flex-order-1 flex-column">
        <span class="text-white text-xl font-bold uppercase block">Company</span>
        <ul class="list-none p-0">
          <li class="mb-4">
            <router-link
              to="/products/base-models"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              Search Base Models
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              to="/customer-service"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              Customer Service
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              to="/services/calibration"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              Calibration Services
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              to="/government"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              Government/GSA Procurement
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-12 sm:col-6 lg:col-3 py-4 flex flex-order-3 lg:flex-order-2 flex-column">
        <span class="text-white text-xl font-bold uppercase block">Legal</span>
        <ul class="list-none p-0">
          <li class="mb-4">
            <router-link
              to="/registered-trademarks"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              Registered Trademarks
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              to="/iso"
              class="router-link text-white transition-duration-150 cursor-pointer border-round p-2 hover:bg-red-500"
              tabindex="0"
            >
              ISO Certification
            </router-link>
          </li>
          <!-- <li class="my-4">
            <a
              tabindex="0"
              class="router-link text-white transition-duration-150 cursor-not-allowed border-round p-2 hover:bg-red-500"
              disabled
            >Terms of Service</a>
          </li> -->
        </ul>
      </div>
      <div class="col-12 sm:col-6 lg:col-3 py-4 flex flex-order-1 lg:flex-order-3 flex-column">
        <p class="m-0 p-0 mb-6 text-center text-2xl">
          Daughtridge Sales is now part of MCE. Same great company, now with more solutions to serve you better.
        </p>
        <div class="text-center">
          <a
            href="https://mceautomation.com/"
            target="_blank"
            class="border-2router-link flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-red-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
            @click="trackEvent(FathomEventNames.GoToMceSite)"
          >
            <img
              src="/images/mce-wsp-logo-white.svg"
              alt="Logo for MCE Automation"
              class="w-8 md:w-9 lg:w-10"
            >
          </a>
        </div>
        <div class="mt-4 text-center">
          <a
            class="router-link text-white border-bottom-1 border-transparent hover:border-white"
            href="https://mceautomation.com/about/news/-acquisition-announcement-daughtridge-sales-company-inc-/"
            target="_blank"
          >
            Read Press Release
          </a>
        </div>
      </div>
    </div>
  </footer>
  <div class="bg-primary-900 text-white px-4 md:px-6 lg:px-8">
    <div class="py-2 flex flex-column sm:flex-row align-items-center justify-content-center text-center">
      <p-btn
        v-if="appStore.isDevelopment"
        class="p-button-sm p-button-link text-white mt-2 lg:mr-2"
        label="Show Breakpoints"
        @click="appStore.showBreakpointsDialog = !appStore.showBreakpointsDialog"
      />
      <div class="mt-2">
        &copy; {{ year }} Daughtridge Sales All rights reserved
      </div>
      <div class="md:ml-2 mt-2">
        v{{ appStore.version }}
      </div>
      <router-link
        to="/iso"
        class="mt-2"
      >
        <img
          class="w-10rem"
          src="/images/Daughtridge-Sales-ISO.png"
          alt="Daughtridge Sales is ISO 9001-2019 Certified"
        >
      </router-link>
    </div>
  </div>
  <dev-breakpoints
    v-if="appStore.isDevelopment && appStore.showBreakpointsDialog"
    @close="appStore.showBreakpointsDialog = false"
  />
</template>
