export default [
  {
    path: '/services',
    name: 'services',
    children: [
      {
        path: 'calibration',
        name: 'calibration',
        component: () => import('../views/Calibration.vue')
      },
      {
        path: 'laser-marking-engraving',
        name: 'laser-marking-engraving',
        component: () => import('../views/LaserMarkingEngraving.vue')
      }
    ]
  },
  {
    path: 'government',
    name: 'government',
    component: () => import('../views/Government.vue')
  }
]
