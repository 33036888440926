interface VendorLogos {
  [key: string]: {
    image: string
    imageBgColor?: string
    hasPage?: boolean
    hasProducts?: boolean
  }
}

export const vendors: VendorLogos = {
  'Ashcroft': {
    image: 'ashcroft-logo.jpg',
    hasPage: false,
    hasProducts: false
  },
  'WIKA': {
    image: 'wika-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Ametek': {
    image: 'ametek-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Chemiquip': {
    image: 'chemiquip-logo.png',
    imageBgColor: 'bg-gray-900',
    hasPage: false,
    hasProducts: false
  },
  'Dansway': {
    image: 'small/dansway-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Dwyer': {
    image: 'dwyer-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'JMS': {
    image: 'small/jms-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Kerotest': {
    image: 'small/kerotest-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Mercoid': {
    image: 'small/mercoid-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Mid-West Instrument': {
    image: 'mid-west-instrument-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'NOSHOK': {
    image: 'noshok-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'OMS': {
    image: 'small/oms-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Pelican': {
    image: 'pelican-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Reotemp': {
    image: 'reotemp-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Tel-Tru': {
    image: 'small/teltru-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Trerice': {
    image: 'small/trerice-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'Weksler': {
    image: 'weksler-logo.png',
    hasPage: false,
    hasProducts: false
  },
  'McDaniel': {
    image: 'mcdaniel-logo.gif',
    hasPage: false,
    hasProducts: false
  },
  'Zavoda': {
    image: 'small/zavoda-logo.png',
    hasPage: false,
    hasProducts: false
  }
}
