import { ref } from 'vue'
import { defineStore } from 'pinia'
import { name as appName, version as appVersion } from '../../package.json'

export const useAppStore = defineStore('AppStore', () => {
  const application = ref(appName)
  const isDevelopment = import.meta.env.VITE_ENV === 'development'
  const version = ref(appVersion)
  const showBreakpointsDialog = ref(false)
  return {
    application,
    isDevelopment,
    showBreakpointsDialog,
    version
  }
})
