import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '../layout/AppLayout.vue'
import Products from './Products'
import Resources from './Resources'
import Services from './Services'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('../views/HomeView.vue')
        },
        {
          path: 'customer-service',
          name: 'customer-service',
          component: () => import('../views/CustomerService.vue')
        },
        {
          path: 'iso',
          name: 'iso',
          component: () => import('../views/Iso.vue')
        },
        {
          path: 'lead-times-and-shipping',
          name: 'lead-times-and-shipping',
          component: () => import('../views/LeadTimesAndShipping.vue')
        },
        {
          path: 'registered-trademarks',
          name: 'registered-trademarks',
          component: () => import('../views/RegisteredTrademarks.vue')
        },
        ...Products,
        ...Resources,
        ...Services

      ]
    },
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
  }
})

export default router
