<script setup lang="ts">
import { computed } from 'vue'
import { useMenuStore } from '../stores/MenuStore'
import AppHeader from './AppHeader.vue'
import AppFooter from './AppFooter.vue'

const menuStore = useMenuStore()
const containerClass = computed(() => {
  return {
    'layout-overlay-active': menuStore.showMenu
  }
})
</script>

<template>
  <div
    class="surface-ground layout-wrapper"
    :class="containerClass"
  >
    <!-- <app-banner /> -->
    <div class="ml-4">
      <p-toast
        group="TODO"
        position="bottom-right"
      />
      <p-toast />
    </div>
    <app-header />
    <main
      class="m-3 lg:m-6 pt-8"
    >
      <router-view />
    </main>
    <app-footer />
  </div>
</template>
